<template>
    <div class="exhibitinoBox">
        <p class="exhibitino">展馆</p>
        <div :class="(item.id % 2 == 0) ? 'img' : 'img2'" @click="toPrefaceHall(item.id)" v-for="item  in listdata"
            :key="item.id">
            <img style="width:100%;height:100%;  border-radius: 0.5rem;" :src="$baseurl + item.url" alt="">
            <div class="info">
                <div class="info-left">{{ item.name }}</div>
                <img class="info-right" style="width:0.6rem; height:0.6rem;" src="../assets/img/左箭头.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>

import { homedata, inc } from '../assets/api/http'
export default {
    data() {
        return {
            listdata: [],
            listid: null,
            isclick: []
        }
    },
    methods: {
        gethomedata() {
            homedata().then((res) => {
                if (res.data.code == 200) {
                    this.listdata = res.data.data
                    // console.log(this.listdata);

                }
            })
        },
        toPrefaceHall(id) {
            let isclick1 = sessionStorage.getItem(`isclick${id}`) || ''
            //   this.isclick.push(id)

            if (id === 1) {
                this.$router.push({
                    path: '/prefacehall',
                    query: { id: id }
                })
                if (isclick1 == id) {
                    console.log('在页面关闭之前已经计数一次了');
                } else {
                    sessionStorage.setItem(`isclick${id}`, id)
                    inc({type:1, id:id, version:1})
                        .then(res => {
                            // console.log(res);
                      
                        })
                }
            } else if (id === 2) {

                this.$router.push({
                    path: '/CivilAir',
                    query: { id: id }
                })

                if (isclick1 == id) {
                    console.log('在页面关闭之前已经计数一次了');
                } else {
                    sessionStorage.setItem(`isclick${id}`, id)
                    inc({type:1, id:id, version:1})
                        .then(res => {
                            // console.log(res);
                        })
                }

            } else if (id === 3) {

                this.$router.push({
                    path: '/DisasterPrevention',
                    query: { id: id }
                })

                if (isclick1 == id) {
                    console.log('在页面关闭之前已经计数一次了');
                } else {
                    sessionStorage.setItem(`isclick${id}`, id)
                    inc({type:1, id:id, version:1})
                        .then(res => {
                            // console.log(res);
                        })
                }

            } else if (id === 4) {

                this.$router.push({
                    path: '/ReviewProspect',
                    query: { id: id }
                })
                // console.log(isclick1);

                if (isclick1 == id) {
                    console.log('在页面关闭之前已经计数一次了');
                } else {
                    sessionStorage.setItem(`isclick${id}`, id)
                    inc({type:1, id:id, version:1})
                        .then(res => {
                            // console.log(res);
                        })
                }

            }

        },

    },
    created() {
        this.gethomedata()
    }


}
</script>

<style lang="scss" scoped>
.exhibitinoBox {
    background: #2A3C44;
    width: 100%;
    // height: 20rem;
    border-radius: 1.2rem 0 0 0;
    position: relative;
    overflow: hidden;
    box-shadow: 0 1px 14px 0 #19282F;

    .exhibitino {
        margin: 0.7rem 0 0.5rem 4%;
        font-size: 0.55rem;
        color: #fff;
    }

    .img {
        background: rgb(92, 103, 106);
        position: relative;
        box-sizing: border-box;
        width: 94%;
        margin: 0.3rem auto;
        height: 4.5rem;
        border-radius: 0.5rem;
        animation: myleft 3s;

        .info {
            position: absolute;
            bottom: 0.3rem;
            display: flex;
            justify-content: space-between;
            width: 100%;
            font-size: 0.5rem;
            color: #fff;
            align-items: center;

            .info-left {
                margin-left: 0.4rem;
                font-size: 0.4rem;
            }

            .info-right {
                margin-right: 0.2rem;
                width: 0.2rem;
            }
        }
    }

    .img2 {
        background: rgb(92, 103, 106);
        position: relative;
        width: 94%;
        margin: 0.5rem auto;
        height: 4.5rem;
        border-radius: 0.5rem;
        animation: myright 3s;

        .info {
            position: absolute;
            bottom: 0.3rem;
            display: flex;
            justify-content: space-between;
            width: 100%;
            font-size: 0.5rem;
            color: #fff;
            align-items: center;

            .info-left {
                margin-left: 0.4rem;
                font-size: 0.4rem;
            }

            .info-right {
                margin-right: 0.2rem;
            }
        }
    }
}

@keyframes myleft {
    0% {
        left: -6rem;
    }

    100% {
        left: 0;
    }
}

@keyframes myright {
    0% {
        left: 6rem;
    }

    100% {
        left: 0;
    }
}
</style>