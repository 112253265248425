<template>

  <swiper class="swiper" :options="swiperOption">
    <swiper-slide v-for=" (item) in this.swiperdata" :key="item.id" >
      <img  :src="$baseurl +item.slideshow" alt="" style="width:100%; height:100%; border-radius: 0.3rem; box-shadow: 0 0 0.1rem 0.1rem rgba(0, 0, 0, 0.3);">
    </swiper-slide>
  
  </swiper>

</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { swiper } from '../assets/api/http'
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperdata: [],//存放轮播图图片数据
      swiperOption: {
        spaceBetween: 15,
        loop: true, //是否循环
        autoplay: true, //是否自动
        slidesPerView: 'auto',
        centeredSlides: true,//是否居中显示
        centerInsufficientSlides: true,
        loopedSlides: 3,// 显示几个轮播图
        speed: 1500, //  轮播时间
      }
    }
  },

  created() {
   this.getswiper()
  },
  methods:{
     getswiper(){
      swiper()
      .then((res) => {
        if(res.data.code === 200){
           this.swiperdata =  res.data.data
          //  window.localStorage.setItem(swiperdata1, res.data.data.id)
          // //  console.log(this.swiperdata);
          // console.log(window.localStorage.getItem(swiperdata1));
      
        }
      })
     }
  }

}
</script>

<style lang="scss" scoped>
.swiper {
  height: 3.75rem;
  width: 100%;
  margin: 0.5rem auto;


  .swiper-slide {
    display: flex;
    border-radius: 0.3rem;
    width: 6rem !important;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;


  }
}
</style>