<template>
  <div class="Homecontainer">
    <!-- <headers></headers> -->
      <headers></headers>
      <swiper ></swiper>
      <exhibitionHall ></exhibitionHall>
  </div>

</template>

<script>
import headers from '../components/header.vue'
import Swiper from '../components/swiper.vue'
import exhibitionHall from '../components/exhibitionHall.vue'

export default {
 
  components: { headers, Swiper, exhibitionHall },

  data() {
    return {

    }
  }
}
</script>

<style lang="scss" >
.Homecontainer {
  background-image: linear-gradient(207deg, #1F2E35 15%, #FFFFFF 100%);
  overflow:hidden;
  // width: 100%;
  // height: 100vh;
}
</style>