<template>
  <div class=" childrenbox" >
    <img src="@/assets/assets/学生版_启动@xh.png"  style="width:100%; height:100%;"  alt="">
  </div>
</template>

<script>
export default {
   data(){
    return{

    }
   },
   mounted(){
    this.topath()
   },
   methods:{
     topath(){
      setTimeout(()=>{
           this.$router.push({
            path:'/childrenPreface'
           })
      },3000)
     }
   }
}
</script>

<style lang="scss">
.childrenbox{
    width: 100%;
    height: 100vh;
}

</style>