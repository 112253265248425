<template>
    <div  class="shoye" >
        <img style="height:100% ; width:100% ;position: absolute;
     z-index: -1;" src="@/assets/assets/xuesbg.png" alt="">
        <div class="headerbox1">
            <p @click="toHome">上海民防科普教育馆</p>
        </div>
       <swiper></swiper>
        <div class=" listbox">
            <div v-if="listdata.length > 0" class="centerbox">
                <div style="margin-right: 0.2rem;">
                    <div class="pro"  @click="toPrefaceHall(listdata[0].id)"  >
                  
                        <img src="@/assets/assets/XG@2x.png" style="width:100%; height:100%" alt="">
                    </div>
                    <div class="pro1" @click="toPrefaceHall(listdata[2].id)">
                       
                        <img src="@/assets/assets/FZJZG@2x.png" style="width:100%; height:100%" alt="">
                    </div>
                </div>
                <div  >
                    <div class="pro1" @click="toPrefaceHall(listdata[1].id)">
                       
                        <img src="@/assets/assets/RMFKG@2x.png" style="width:100%; height:100%" alt="">
                    </div>
                    <div class="pro" @click="toPrefaceHall(listdata[3].id)">
                    
                        <img src="@/assets/assets/JY@2x.png"  style="width:100%; height:100%" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { homedata,inc } from '@/assets/api/http'
import Swiper from '@/components/swiper.vue'
export default {
  components: { Swiper },

    data() {
        return {
            listdata: [],
            listid: null,
        }
    },
    methods: {
        //初始化获取数据
        gethomedata() {
            homedata().then((res) => {
                if (res.data.code == 200) {
                    this.listdata = res.data.data
                }
            })
        },
        toPrefaceHall(id) {
            let isclick1 = sessionStorage.getItem(`chidlisclick${id}`) || ''
            //   this.isclick.push(id)
            if (id === 1) {
                this.$router.push({
                    path: '/PrefaceHallchild',
                    query: { id: id }
                })
                if (isclick1 == id) {
                } else {
                    sessionStorage.setItem(`chidlisclick${id}`, id)
                    inc({type:1,id:id,version:2})
                        .then(res => {
                            // console.log(res);
                        })
                }
            } else if (id === 2) {
                this.$router.push({
                    path: '/CivilAirchild',
                    query: { id: id }
                })
                if (isclick1 == id) {

                } else {
                    sessionStorage.setItem(`chidlisclick${id}`, id)
                    inc({type:1,id:id,version:2})
                        .then(res => {
                            console.log(res);
                        })
                }

            } else if (id === 3) {
                this.$router.push({
                    path: '/DisasterPreventionchild',
                    query: { id: id }
                })
                if (isclick1 == id) {
                } else {
                    sessionStorage.setItem(`chidlisclick${id}`, id)
                    inc({type:1,id:id,version:2})
                        .then(res => {
                            console.log(res);
                        })
                }
            } else if (id === 4) {

                this.$router.push({
                    path: '/ReviewProspectchild',
                    query: { id: id }
                })
                if (isclick1 == id) {

                } else {
                    sessionStorage.setItem(`chidlisclick${id}`, id)
                    inc({type:1,id:id,version:2})
                        .then(res => {
                            console.log(res);
                        })
                }

            }

        },
  
        toHome() {
            let r = this.$route
            if (r.path == '/childrenPreface') {
                console.log('已经在首页了');
            } else {
                this.$router.push('/childrenPreface')
            }

        }

    },
    created() {
        this.gethomedata()
    }

}
</script>

<style lang="scss" scoped>

.headerbox1 {
    height: 1.3rem;
    width: 100%;
    color: #fff;
    font-size: 0.44rem;
    text-align: center;
    line-height: 1.3rem;
}

.swiper {
    height: 3.75rem;
    width: 100%;
    margin: 0.5rem auto;
    .positionname {
        position: absolute;
        bottom: 0.2rem;
        font-size: 0.3rem;
        color: #fff;
    }

    .swiper-slide {
        display: flex;
        border-radius: 0.3rem;
        width: 6rem !important;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: bold;
        position: relative;

    }
}

.listbox {
    margin: 0.5rem auto 0 auto;
    .centerbox {
        display: flex;
        width: 6.4rem;
        margin: 0 auto;
        justify-content: space-between;
        flex-wrap: wrap;
        .colbox{
            display: flex;
            flex-direction: column;
        }

        .pro {
            flex: 1;
            position: relative;
            color: #fff;
            width: 3rem;
            min-width: 2.8rem;
            margin: 0 0 0.2rem 0;
            height: 3.6rem;
            background: rgb(140, 154, 175);
            border-radius: 0.5rem;
            font-size: 0.5rem;

            .reofont {
                position: absolute;
                top: 0.3rem;
                left: 0.3rem;
            }

        }

        .pro1 {
            flex: 1;
            position: relative;
            color: #fff;
            width: 3rem;
            min-width: 2.8rem;
            margin: 0 0 0.2rem 0;
            height: 4.2rem;
            background: rgb(140, 154, 175);
            border-radius: 0.5rem;
            font-size: 0.5rem;

            .reofont {
                position: absolute;
                top: 0.3rem;
                left: 0.3rem;
            }

        }

    }
}


.exhibitinoBox {

    .exhibitino {
        margin: 0.7rem 0 0.5rem 0.9rem;
        font-size: 0.55rem;
        color: #fff;

    }

    

    
}

</style>