<template>
  <div class="container">
    <div class=" childrenbox">
      <img src="@/assets/assets/启动.png" style="width:100%; height: 100%;  " alt="">
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {

    }
  },
  mounted() {
   this.$parent.aaa = 2
    this.topath()
  },
  methods: {
    topath() {
      setTimeout(() => {
        this.$router.push({
          path: '/Home'
        })
      }, 3000)
    }
  }
}
</script>

<style lang="scss" >
.container {

  overflow: hidden;
  width: 100%;
  height: 100%;
}

.childrenbox {
  width: 100%;
 height: 100%;
  background: rgb(31, 47, 54);
}
</style>