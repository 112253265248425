<template>
  <div class="container">
    <!-- <headers></headers> -->
        <chidl-home></chidl-home>
  </div>

</template>

<script>

import ChidlHome from '@/views/childrenHome/chidlHome.vue'
export default {
 
  components: { ChidlHome },

  data() {
    return {

    }
  }
}
</script>

<style lang="scss" >
.container {
  background: linear-gradient(rgb(31, 46, 53), rgb(92, 103, 106));
  overflow:hidden;
  // width: 100%;
  // height: 100vh;
}
</style>